<template>
  <div>
    <Carousel :texta="false" :height="height" :imgList="imgList" />
    <briefIntroduction />
    <introduce />
    <Productcenter />
    <Journalism />
  </div>
</template>
<script>
export default {
  components: {},
  //数据
  data() {
    return {
      height: "600px",
      imgList: [
        require("@/assets/back.jpg"),
        require("@/assets/bigimg.jpg"),
        require("@/assets/bigimgC.jpg"),
      ],
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang='scss'>
</style>
